import { useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { PricesCardComponent } from "./PricesCardComponent";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPlan } from "../../../store/Slices/paymentSlice";
import { useNavigate } from "react-router-dom";
import pricesData from "./pricesData.json";

export const PricesComponent = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const plans = pricesData;

  const onCardSelected = (type) => {
    dispatch(setSelectedPlan({ plan: type }));
    navigate("/registration", { replace: true });
  };

  return (
    <div id="prices">
      <Box
        sx={{
          mt: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 3,
          }}
        >
          {ReactHtmlParser(t("prices.title"))}
        </Typography>
        <svg
          width="175"
          height="13"
          viewBox="0 0 175 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_279_8604)">
            <path
              d="M38.0874 2.64715C54.7105 2.2664 71.3427 2.02163 87.9749 1.91284C104.607 1.80406 121.085 1.84032 137.636 2.01256C147.038 2.11229 156.449 2.24827 165.851 2.42958L167.127 0.571142C145.663 0.94283 124.207 1.40517 102.752 1.95817C81.2967 2.51117 59.8414 3.15482 38.386 3.8982C26.3417 4.31521 14.3064 4.75036 2.26211 5.22177C1.55628 5.24897 0.49754 5.43028 0.0993808 6.11926C-0.271631 6.76291 0.416098 7.09834 0.98619 7.08021C23.3464 6.50908 45.7248 6.45468 68.0941 6.92609C90.4634 7.3975 112.815 8.40378 135.139 9.92679C147.681 10.779 160.214 11.8034 172.729 12.9909C173.407 13.0544 174.529 12.719 174.891 12.0935C175.289 11.4135 174.547 11.1778 174.004 11.1325C151.735 9.02024 129.411 7.4247 107.068 6.35496C84.7263 5.28523 62.3479 4.75036 39.9786 4.7413C27.4095 4.7413 14.8403 4.89541 2.27116 5.22177L0.995239 7.08021C22.4415 6.23711 43.8969 5.49374 65.3522 4.83195C86.8076 4.17923 108.263 3.6081 129.718 3.13669C141.763 2.87379 153.816 2.62902 165.86 2.42051C166.566 2.41145 167.634 2.20294 168.023 1.52303C168.412 0.843109 167.697 0.571142 167.136 0.562077C150.495 0.253848 133.872 0.0634713 117.24 0.0181436C100.607 -0.0271842 84.1291 0.0544058 67.5783 0.281045C58.1763 0.407962 48.7653 0.580208 39.3633 0.797781C38.6575 0.815912 37.5897 1.01535 37.2006 1.69527C36.8296 2.33892 37.5173 2.67435 38.0874 2.65622V2.64715Z"
              fill="#2B3674"
            />
          </g>
          <defs>
            <clipPath id="clip0_279_8604">
              <rect width="175" height="13" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 8,
          }}
        >
          {plans.map((plan) => {
            return (
              <Grid
                item
                key={plan.id}
                xs={12}
                md={6}
                lg={3}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <PricesCardComponent
                  pricesData={plan}
                  onCardSelected={onCardSelected}
                />
              </Grid>
            );
          })}
        </Grid>
        <Typography
          sx={{
            maxWidth: "800px",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            px: "20px",
            mt: 5,
            lineHeight: "20px",
          }}
        >
          {ReactHtmlParser(t("prices.text"))}
        </Typography>
      </Box>
    </div>
  );
};
