import { useState } from "react";
import b120Text from "../../Assets/b120-text.png";
import lifetresor from "../../Assets/the-LIFETRESOR.png";
import { Box, Button, Stack, Link, Drawer } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export const MobileTopMenu = () => {
  const [language, setLanguage] = useState("hu");
  const [toggleMenu, setToggleMenu] = useState(false);
  const toggleDrawer = () => {
    setToggleMenu(!toggleMenu);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    console.log(language);
  };

  const navigate = useNavigate();

  const loginPage = () => {
    navigate("/login");
  };

  const registrationPage = () => {
    navigate("/registration");
  };

  const scrollToSection = (sectionId) => {
    const element = document.querySelector("#" + sectionId);
    if (element) {
      const headerOffset = 30;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "fixed",
          zIndex: 5,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          mt: 6,
          mb: 22,
          py: 2,
          px: 2,
          alignItems: "center",
        }}
      >
        <Box component="img" alt="background" src={b120Text}></Box>
        <Box component="img" alt="background" src={lifetresor}></Box>
        <Button sx={{ ml: 8, width: "auto" }} onClick={toggleDrawer}>
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="46" height="46" rx="23" fill="#2B3674" />
            <line x1="13" y1="15.5" x2="32" y2="15.5" stroke="white" />
            <line x1="13" y1="22.5" x2="32" y2="22.5" stroke="white" />
            <line x1="13" y1="29.5" x2="32" y2="29.5" stroke="white" />
          </svg>
        </Button>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "relative",
          zIndex: 4,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          mt: 6,
          mb: 22,
          py: 2,
          px: 2,
          alignItems: "center",
        }}
      ></Stack>
      <Drawer
        open={toggleMenu}
        PaperProps={{
          sx: { width: "100%", bgcolor: "primary.dark" },
        }}
      >
        <Box sx={{ mt: 10, px: 3 }}>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "100px",
              }}
            ></Box>
            <Button
              onClick={toggleDrawer}
              variant="text"
              sx={{
                bgcolor: "#FFFFFF",
                minWidth: "50px",
                width: "50px !important",
                height: "50px",
                p: 0,
                borderRadius: "32px",
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9997 15.0578L21.5283 9.52925C21.7886 9.2689 22.2107 9.2689 22.4711 9.52925C22.7314 9.7896 22.7314 10.2117 22.4711 10.4721L16.9425 16.0007L22.4711 21.5292C22.7314 21.7896 22.7314 22.2117 22.4711 22.4721C22.2107 22.7324 21.7886 22.7324 21.5283 22.4721L15.9997 16.9435L10.4711 22.4721C10.2107 22.7324 9.78862 22.7324 9.52827 22.4721C9.26792 22.2117 9.26792 21.7896 9.52827 21.5292L15.0569 16.0007L9.52827 10.4721C9.26792 10.2117 9.26792 9.7896 9.52827 9.52925C9.78862 9.2689 10.2107 9.2689 10.4711 9.52925L15.9997 15.0578Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
          </Stack>
          <Box
            sx={{
              height: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              sx={{ mt: 4, pl: 2 }}
              onClick={toggleDrawer}
            >
              <Link
                onClick={() => scrollToSection("howitworks")}
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.howitworks")}
              </Link>
              <Link
                onClick={() => scrollToSection("whoisitfor")}
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.whoisitfor")}
              </Link>
              <Link
                onClick={() => scrollToSection("prices")}
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.prices")}
              </Link>
              <Link
                onClick={() => scrollToSection("faq")}
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.faq")}
              </Link>
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
