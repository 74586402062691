import React, { useState } from "react";
import { Box, Button, Stack, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import B120Logo from "../../Commons/Icons/B120Logo";

export const DesktopTopMenu = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    const element = document.querySelector("#" + sectionId);
    if (element) {
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const menuItems = [
    { id: "howitworks", label: t("topmenu.howitworks") },
    { id: "whoisitfor", label: t("topmenu.whoisitfor") },
    { id: "prices", label: t("topmenu.prices") },
    { id: "faq", label: t("topmenu.faq") },
  ];

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 5,
        bgcolor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid
        container
        direction={"row"}
        sx={{
          width: "100%",
          mr: 2,
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={7}
          sx={{ pr: 2, display: "flex", justifyContent: "center", pl: "20px" }}
        >
          <Box
            sx={{
              my: "13px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack
              spacing={{ xs: 1, md: 1, lg: 3 }}
              direction={"row"}
              sx={{ alignItems: "center" }}
            >
              <B120Logo />
              {menuItems.map((item) => (
                <Button
                  key={item.id}
                  variant="text"
                  onClick={() => scrollToSection(item.id)}
                  className="main-blue-text-btn"
                >
                  {item.label}
                </Button>
              ))}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              my: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          ></Box>
        </Grid>
      </Grid>
    </Box>
  );
};
