import { useEffect, useState } from 'react';

import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import HomeComponent from '../HomePage/HomeComponent';

const AuthNavigator = () => {


  //const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  //const bhistory = useNavigate(/*{basename: baseUrl}*/);

  const [initializing, setInitializing] = useState(true);
  
  useEffect(() => {
      if (initializing) {
        setInitializing(false);
      }
  }, []);

  if (initializing) return 'Loading....';

  const basename = process.env.REACT_APP_BASE_NAME || '';

  return (
	<>
    <BrowserRouter basename={basename}>
	<Routes>  
    <Route path="/"  element={<HomeComponent title={""}/>} />
	</Routes>
	</BrowserRouter>

	</>
  );
}

export default AuthNavigator;